import React from "react";
import {
  FaUsers, FaTachometerAlt, FaHandHoldingUsd, FaUserCog, FaChartPie, FaAngleLeft, FaPowerOff, FaBars, FaAngleDown, FaPlus, FaMapPin, FaClock, FaSearch, FaCogs,
  FaGlobe
} from "react-icons/fa";

import { AiOutlineFileText } from "react-icons/ai";
import { BiSolidBriefcase } from "react-icons/bi";
import { PiNotePencilBold } from "react-icons/pi";
import { BsSquareFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import RestService from "../http";
import { GiExpense } from "react-icons/gi";
// import formatDate from "./utilityComponents/FormatDate";
import { Link } from "react-router-dom";


export default function Navbar() {
  // const [formId, setFormId] = useState("");
  const [searchVisible] = useState(false);
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [adminProfit, setAdminProfit] = useState({});


  // useEffect(() => {
  //   var raw = {
  //     "startDate": formatDate(startDate),
  //     "endDate": formatDate(endDate)
  //   }

  //   rest.post("report/fetchAdminProfit", raw).then(result => {

  //     // console.log(result);

  //     if (result.status === rest.SUCCESS) {
  //       const profitData = result.data;
  //       setAdminProfit(profitData);

  //     } else {
  //       setAdminProfit(undefined);

  //     }

  //   }).catch((error) => {
  //     console.log(error);
  //   })
  // }, []);


  const logout = (url) => {
    Swal.fire({
      title: "Are you sure you want to logout ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      buttons: {
        confirm: {
          text: "YES",
          className: "btn btn-primary",
        },
        cancel: {
          text: "No",
          className: "btn btn-danger",
        },
      },
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed.isConfirmed) {
        // Redirect to the login page (this is just an example)
        window.location.href = "/";
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
      }
    });
  };


  useEffect(() => {
    checkSidebar();
  }, []);

  const checkSidebar = () => {
    const sidebar = localStorage.getItem("sidebar");
    let src = "#";
    if (sidebar != null) {
      if (sidebar === "1") {
        $("#body").removeClass("sidebar-collapse sidebar-open");
      } else {
        $("#body").addClass("sidebar-collapse sidebar-close");
      }
      $("#brand_logo").attr("src", src);
    }
  };

  const collapseSidebar = () => {
    let src = "";
    const sidebar = localStorage.getItem("sidebar");
    if (sidebar == null) {
      $("#body").addClass("sidebar-collapse");
      localStorage.setItem("sidebar", "2");
    } else {
      if (sidebar === "2") {
        $("#body").removeClass("sidebar-collapse");
        localStorage.setItem("sidebar", "1");
      } else if (sidebar === "1") {
        $("#body").addClass("sidebar-collapse");
        localStorage.setItem("sidebar", "2");
      }
    }
    $("#brand_logo").attr("src", src);
    if ($("#body").hasClass("sidebar-open")) {
      $("#body").removeClass("sidebar-open");
      $("#body").addClass("sidebar-close");
    } else {
      $("#body").removeClass("sidebar-close");
      $("#body").addClass("sidebar-open");
    }
  };

  const rest = new RestService();
  const [memberDropdownOpen, setMemberDropdownOpen] = useState(false);
  const [transactionDropdownOpen, setTransactionDropdownOpen] = useState(false);
  const [walletDropdownOpen, setWalletDropdownOpen] = useState(false);
  const [expenseDropdownOpen, setExpenseDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const [commSettingsDropdownOpen, setCommSettingsDropdownOpen] = useState(false);
  const [apiDropdownOpen, setApiDropdownOpen] = useState(false);
  const [commissionDropdownOpen, setCommissionDropdownOpen] = useState(false);
  const [adminCommDropdownOpen, setAdminCommDropdownOpen] = useState(false);
  const [utilitiesDropdownOpen, setUtilitiesDropdownOpen] = useState(false);
  const [permissionDropdownOpen, setPermissionDropdownOpen] = useState(false);
  const [hrDropdownOpen, setHrDropdownOpen] = useState(false);
  const [nsdlDropdownOpen, setNsdlDropdownOpen] = useState(false);
  const [reportsDropdownOpen, setReportsDropdownOpen] = useState(false);
  const [webDropdownOpen, setWebDropdownOpen] = useState(false);
  const [developerDropdownOpen, setDeveloperDropdownOpen] = useState(false);
  const [name] = useState(sessionStorage.getItem("name"));
  const [roleName] = useState(sessionStorage.getItem("role"));


  const toggleMemberDropdown = () => {
    setMemberDropdownOpen(!memberDropdownOpen);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
  };

  const toggleTransactionDropdown = () => {
    setTransactionDropdownOpen(!transactionDropdownOpen);
    setMemberDropdownOpen(false);
    setWalletDropdownOpen(false);
  };

  const toggleWalletDropdown = () => {
    setWalletDropdownOpen(!walletDropdownOpen);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
  };

  const toggleExpenseDropdown = () => {
    setExpenseDropdownOpen(!expenseDropdownOpen);
    setWalletDropdownOpen(false);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
  };

  const toggleSupportDropdown = () => {
    setSupportDropdownOpen(!supportDropdownOpen);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
  };

  const toggleCommSettingsDropdown = () => {
    setCommSettingsDropdownOpen(!commSettingsDropdownOpen);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
  };

  const toggleSettingsDropdown = () => {
    setSettingsDropdownOpen(!settingsDropdownOpen);
    setCommSettingsDropdownOpen(false);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
  };

  const toggleUtilitiesDropdown = () => {
    setUtilitiesDropdownOpen(!utilitiesDropdownOpen);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
    setSettingsDropdownOpen(false);
    setCommSettingsDropdownOpen(false);
  };

  const toggleApiDropdown = () => {
    setApiDropdownOpen((prevState) => !prevState);
  };

  const toggleCommissionDropdown = () => {
    setCommissionDropdownOpen((prevState) => !prevState);
  };

  const toggleAdminCommissionDropdown = () => {
    setAdminCommDropdownOpen((prevState) => !prevState);
  };


  const togglePermissionDropdown = () => {
    setPermissionDropdownOpen(!permissionDropdownOpen)
    setHrDropdownOpen(false);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
    setSettingsDropdownOpen(false);
    setCommSettingsDropdownOpen(false);
    setUtilitiesDropdownOpen(false);
  };


  const toggleHrDropdown = () => {
    setHrDropdownOpen(!hrDropdownOpen);
    setPermissionDropdownOpen(false);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
    setSettingsDropdownOpen(false);
    setCommSettingsDropdownOpen(false);
    setUtilitiesDropdownOpen(false);
  };

  const toggleNsdlDropdown = () => {
    setNsdlDropdownOpen(!nsdlDropdownOpen)
    setHrDropdownOpen(false);
    setPermissionDropdownOpen(false);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
    setSettingsDropdownOpen(false);
    setCommSettingsDropdownOpen(false);
    setUtilitiesDropdownOpen(false);
  };

  const toggleReportsDropdown = () => {
    setReportsDropdownOpen(!reportsDropdownOpen);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
    setSettingsDropdownOpen(false);
    setCommSettingsDropdownOpen(false);
    setUtilitiesDropdownOpen(false);
    setHrDropdownOpen(false);
    setPermissionDropdownOpen(false);
  };

  const toggleWebDropdown = () => {
    setWebDropdownOpen(!webDropdownOpen);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
    setSettingsDropdownOpen(false);
    setCommSettingsDropdownOpen(false);
    setUtilitiesDropdownOpen(false);
    setHrDropdownOpen(false);
    setReportsDropdownOpen(false);
    setPermissionDropdownOpen(false);
  };

  const toggleDeveloperDropdown = () => {
    setDeveloperDropdownOpen(!developerDropdownOpen);
    setMemberDropdownOpen(false);
    setTransactionDropdownOpen(false);
    setWalletDropdownOpen(false);
    setProfileDropdownOpen(false);
    setSupportDropdownOpen(false);
    setSettingsDropdownOpen(false);
    setCommSettingsDropdownOpen(false);
    setUtilitiesDropdownOpen(false);
    setHrDropdownOpen(false);
    setReportsDropdownOpen(false);
    setWebDropdownOpen(false);
    setPermissionDropdownOpen(false);
  };

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formattedTime = currentTime.toLocaleTimeString();

  return (
    <div id="body" className="hold-transition sidebar-mini layout-fixed">
      {/* sidebar-mini layout-fixed  */}

      <div className="wrapperr" id="layout-wrapper">

        {/* Navbar  */}

        <nav
          className="main-header navbarr navbar-expand navbar-white navbar-light gredient"
          style={{
            backgroundImage: "linear-gradient(to right, #ff8000, #ff0000,  #1158AA )",
          }}
        >
          <ul className="navbar-nav">
            {/* <li className="nav-item d-block d-sm-none">
              <a className="nav-link" data-widget="pushmenu">
                <FaBars className="fas fa-bars" />
              </a>
            </li> */}
            <li className="nav-item ">
              <a className="nav-link" onClick={collapseSidebar}>
                <FaBars className="fas fa-bars" />
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link "
                href="/transfered"
                style={{ fontSize: "15px" }}
              >
                <FaPlus className="fas fa-plus" /> Add Money{" "}
              </a>
            </li>

            <li className="nav-item ">
              <a
                href="#!"
                className="nav-link border-left"
              >
                <FaMapPin className="fas fa-map-pin" /> : 122.161.49.112
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#!"
                className="nav-link  border-left border-muted"
              >
                <FaClock
                  className="fas far fa-clock"
                  style={{ fontSize: "15px" }}
                />
                &nbsp;
                <span id="date_time">{formattedTime}</span>
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#!"
                className="nav-link  border-left border-muted"
                onClick="show_search(1)"
                style={{ fontSize: "15px" }}
              >
                <FaSearch className="fas fa-search" /> Search
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#!"
                className="nav-link  border-left border-muted"
                style={{ fontSize: "15px" }}
              >
                <FaTachometerAlt className="fas fa-tachometer-alt" /> Live
                Recharge
              </a>
            </li>
          </ul>

          {/* Right navbar links  */}

          <ul className="navbar-nav ml-auto">
            <li className="nav-item ">
              <a
                href="#!"
                className="nav-link text-info border-left border-muted"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Recharge and Utility Balance"
                style={{ fontSize: "15px", marginTop: "10px" }}
              >
                {/* <AiFillMobile className="fas fa-mobile" /> {adminProfit === undefined ? 0 : adminProfit.totalProfit} */}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link border-left border-muted text-active"
                title="Logout"
                onClick={() => logout("your-url")}
                href="#!"
                style={{ marginTop: "8px" }}
              >
                <FaPowerOff />
              </a>
              {/* <a className="nav-link  border-left border-muted text-active" title="Logout" onClick="logout('logout')" href="#!"><i className="fas fa-power-off"></i></a> */}
            </li>
          </ul>
        </nav>
        <div
          className={searchVisible ? "" : "d-none"}
          id="search_div"
          style={{ backgroundColor: "#0053AF" }}
        >
          <form action="#" method="post" accept-charset="utf-8">
            <input
              type="hidden"
              name="securityToken"
              value="36f49cd8777d81015ef0eb7e51fa7dbd"
            />
            <div className="row p-1">
              <div className="col-3"></div>
              <div className="col-1">
                <select
                  className="form-control form-control-sm"
                  name="action"
                  required
                >
                  <option value="number" selected>
                    Number
                  </option>
                  <option value="txn_id">Txn ID</option>
                </select>
              </div>
              <div className="col-3">
                <div className="input-group input-group-sm">
                  <input
                    className="form-control form-control-sm"
                    type="search"
                    name="value"
                    placeholder="Search"
                    aria-label="Search"
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm "
                      style={{ backgroundColor: "#767679" }}
                      type="submit"
                    >
                      <i className="fas fa-search"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-1 pt-1">
                <a
                  href="#!"
                  className="text-active"
                  onClick="show_search(2)"
                >
                  <i className="fas fa-close"></i>
                </a>
              </div>
            </div>
          </form>
        </div>

        {/* /.navbar  */}

        {/* Main Sidebar Container */}

        <aside
          className="main-sidebar sidebar-dark-primary elevation-4"
          style={{
            backgroundImage: "linear-gradient(to bottom, #1158AA , #ff0000,  #ff8000)",
          }}
        >
          {/* Brand Logo  */}

          <div className="text-center" >
            <a href="/admin" className="brand-link bg-white">
              <img
                loading="lazy"
                src="./image/softmintlogo.png"
                style={{
                  marginTop: "-11px",
                  width: "161px",

                }}
              />
            </a>
          </div>

          {/* Sidebar user panel (optional)  */}

          {/* Sidebar Menu  */}

          <div className="sidebar" style={{ scrollbarWidth: "none" }}>
            <div className="user-panel mt-2 mb-2 d-flex">
              <div className="justify-content-center align-self-center">
                <img
                  loading="lazy"
                  src="./image/profile.png"
                  className="img-square elevation-2"
                  alt="Image"
                  style={{ borderRadius: "50px", width: "40px" }}
                />
              </div>
              <div className="info">
                <a
                  href="#!"
                  className="d-block"
                  style={{ paddingLeft: "18px", color: 'white', textDecoration: "none", fontWeight: "bold", textTransform: "uppercase" }}
                >
                  {name}
                </a>
                <small className="text-white" style={{ paddingLeft: "18px", fontWeight: "500" }}>
                  {/* {roleName} */}
                  {rest.roles[new Number(roleName)]}
                  <i className="fa fa-circle text-success pl-2"></i>
                </small>
              </div>
            </div>

            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li className="nav-item">
                  <Link to={'/admin'} className="nav-link active">
                    <FaTachometerAlt className="nav-icon fas fa-tachometer-alt" />
                    <p >Dashboard</p>
                  </Link>
                </li>

                <li
                  className={`nav-item has-treeview ${memberDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link"
                    onClick={toggleMemberDropdown}
                  >
                    <FaUsers className="nav-icon fas fa-users" />
                    <p style={{ fontWeight: '600' }}>
                      Member
                      {memberDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>

                  <ul
                    className={`nav nav-treeview ${memberDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item">
                      <Link to={'/newuserpage'} className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Manage Member</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={'/deactivemember'} className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Downline Users</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={'/admininsurance'} className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Admin Insurance</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={'/fetchusertxn'} className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>User Txn. Activity</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/altswitch" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Alt Switch</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/memberkyc" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Member KYC</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/logindetail" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Login Details</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/addutiagent" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Add Uti Agent</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/membercommison" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Member Commission</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/addmatmagent" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>MATM user map</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/changesponser" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>User Map</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/viewaepsuser" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>View AEPS User</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageMiniWallet" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Ministatement Wallet</p>
                      </Link>
                    </li>

                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${transactionDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link"
                    onClick={toggleTransactionDropdown}
                  >
                    <PiNotePencilBold className="nav-icon fas fa-edit" />
                    <p style={{ fontWeight: '600' }}>
                      Transaction
                      {transactionDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>

                  <ul
                    className={`nav nav-treeview ${transactionDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item">
                      <Link to="/transactionhis" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Transaction History</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <a href="/pendingtrans" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Pending Transaction</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/refundtrans" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Refund Transaction</p>
                      </a>
                    </li> */}
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${walletDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleWalletDropdown}
                  >
                    <FaHandHoldingUsd className="nav-icon fas fa-hand-holding-usd" />
                    <p style={{ fontWeight: '600' }}>
                      Wallet
                      {walletDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${walletDropdownOpen ? "open" : ""
                      }`}
                  >
                    {/* <li className="nav-item">
                      <a href="/walletSummary" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Wallet Summary</p>
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <Link to="/fundtrans" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Fund Transfer</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <a href="/fundreqmanager" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Fund Request Manager</p>
                      </a>
                    </li> */}
                    {/* <li className="nav-item">
                      <a href="/virtualbal" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Virtual Balance</p>
                      </a>
                    </li> */}
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${expenseDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleExpenseDropdown}
                  >
                    <GiExpense className="nav-icon fas fa-hand-holding-usd" />
                    <p style={{ fontWeight: '600' }}>
                      Admin Expenses
                      {expenseDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${expenseDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item">
                      <Link to="/expanse" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Expenses</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${profileDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleProfileDropdown}
                  >
                    <FaUserCog className="nav-icon fas fa-user-cog" />
                    <p style={{ fontWeight: '600' }}>
                      Profile
                      {profileDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${profileDropdownOpen ? "open" : ""
                      }`}
                  >
                    {/* <li className="nav-item">
                      <a href="/profileupdate" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Profile Update</p>
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <Link to="/changepaswrd" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Change Password</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${supportDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleSupportDropdown}
                  >
                    <FaChartPie className="nav-icon fas fa-chart-pie" />
                    <p style={{ fontWeight: '600' }}>
                      Support
                      {supportDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${supportDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item has-treeview">
                      <Link to="/dispute" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Dispute</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item has-treeview">
                      <a href="/contactinfo" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Contact Information</p>
                      </a>
                    </li> */}
                    {/* <li className="nav-item has-treeview">
                      <a href="custcare" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Customer Care</p>
                      </a>
                    </li> */}
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${commSettingsDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleCommSettingsDropdown}
                  >
                    <FaCogs className="fa fa-cogs mr-2" aria-hidden="true" />
                    <p style={{ fontWeight: '600' }}>
                      Commission Settings
                      {commSettingsDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>

                  <ul
                    className={`nav nav-treeview ${commSettingsDropdownOpen ? "open" : ""
                      }`}
                    style={{ overflowX: "hidden", overflowY: "scroll" }}
                  >
                    <li
                      className={`nav-item has-treeview ${apiDropdownOpen ? "menu-open" : ""
                        }`}
                    >
                      <a
                        href="#!"
                        className="nav-link"
                        onClick={toggleApiDropdown}
                      >
                        <BsSquareFill
                          className="fas fa-square nav-icon"
                          aria-hidden="true"
                        />
                        <p>
                          API
                          {apiDropdownOpen ? (
                            <FaAngleDown className="fas fa-angle-down right" />
                          ) : (
                            <FaAngleLeft className="fas fa-angle-left right" />
                          )}
                        </p>
                      </a>
                      <ul
                        className={`nav nav-treeview ${apiDropdownOpen ? "open" : ""
                          }`}
                      >
                        <li className="nav-item ml-2">
                          <Link to="/apicommison" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>Api</p>
                          </Link>
                        </li>
                        {/* <li className="nav-item ml-2">
                          <Link to="/apiswitching" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>API Switching</p>
                          </Link>
                        </li> */}

                        <li className="nav-item ml-2">
                          <Link to="/commission" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>Commission Setup</p>
                          </Link>
                        </li>

                        {/* <li className="nav-item ml-2">
                          <a href="/apiamount" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>API Amounts</p>
                          </a>
                        </li> */}

                        <li className="nav-item ml-2">
                          <Link to="/apicredential" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>API Credentials</p>
                          </Link>
                        </li>

                        {/* <li className="nav-item ml-2">
                          <a href="/smsapi" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>SMS API</p>
                          </a>
                        </li> */}

                        {/* <li className="nav-item ml-2">
                          <a href="/utilityapi" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>Utility Api</p>
                          </a>
                        </li> */}
                      </ul>
                    </li>

                    <li className="nav-item">
                      <Link to="/addslabs" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Add Slabs</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/assignpackage" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Assign Package</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link to="/addglobalpancharge" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Add Global Pan Charge</p>
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/addcommcharge" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Add Comm Charge</p>
                      </Link>
                    </li>

                    <li
                      className={`nav-item has-treeview ${commissionDropdownOpen ? "menu-open" : ""
                        }`}
                    >
                      <a
                        href="#!"
                        className="nav-link"
                        onClick={toggleCommissionDropdown}
                      >
                        <BsSquareFill
                          className="fas fa-square nav-icon"
                          aria-hidden="true"
                        />
                        <p>
                          User Commission
                          {commissionDropdownOpen ? (
                            <FaAngleDown className="fas fa-angle-down right" />
                          ) : (
                            <FaAngleLeft className="fas fa-angle-left right" />
                          )}
                        </p>
                      </a>
                      <ul
                        className={`nav nav-treeview ${commissionDropdownOpen ? "open" : ""
                          }`}
                      >
                        <li className="nav-item ml-2">
                          <Link to="/usercommission" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>Commission Setup</p>
                          </Link>
                        </li>
                      </ul>
                    </li>


                    <li
                      className={`nav-item has-treeview ${adminCommDropdownOpen ? "menu-open" : ""
                        }`}
                    >
                      <a
                        href="#!"
                        className="nav-link"
                        onClick={toggleAdminCommissionDropdown}
                      >
                        <BsSquareFill
                          className="fas fa-square nav-icon"
                          aria-hidden="true"
                        />
                        <p>
                          Admin Commission
                          {commissionDropdownOpen ? (
                            <FaAngleDown className="fas fa-angle-down right" />
                          ) : (
                            <FaAngleLeft className="fas fa-angle-left right" />
                          )}
                        </p>
                      </a>
                      <ul
                        className={`nav nav-treeview ${adminCommDropdownOpen ? "open" : ""
                          }`}
                      >
                        <li className="nav-item ml-2">
                          <Link to="/admincommission" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>Admin Commission Setup</p>
                          </Link>
                        </li>

                        <li className="nav-item ml-2">
                          <Link to="/masteradmincomm" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>Master Admin Comm Setup</p>
                          </Link>
                        </li>

                        <li className="nav-item ml-2">
                          <Link to="/bbpsadmincomm" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>BBPS Admin Comm Setup</p>
                          </Link>
                        </li>

                        <li className="nav-item ml-2">
                          <Link to="/bbpsusercomm" className="nav-link">
                            <BsSquareFill className="fas fa-square nav-icon" />
                            <p>BBPS User Comm Setup</p>
                          </Link>
                        </li>

                      </ul>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${settingsDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleSettingsDropdown}
                  >
                    <FaCogs className="fa fa-cogs mr-2" aria-hidden="true" />
                    <p style={{ fontWeight: '600' }}>
                      Settings
                      {settingsDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${settingsDropdownOpen ? "open" : ""
                      }`}
                    style={{ overflowX: "hidden", overflowY: "scroll" }}
                  >

                    {/* <li className="nav-item">
                      <a href="/packagemast" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Package Master</p>
                      </a>
                    </li> */}

                    <li className="nav-item">
                      <Link to={'/servicemast'} className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Services Master</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={'/operatormast'} className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Operator Master</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/packagemast" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Add Plan</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/chargesetup" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Charge Setup</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/rechargeset" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Recharge Setting</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link to="/amountblock" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Recharge Amount Block</p>
                      </Link>
                    </li> */}

                    {/* <li className="nav-item">
                      <Link to="/amountcharge" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Account Charges</p>
                      </Link>
                    </li> */}

                    {/* <li className="nav-item">
                      <Link to="/upiapps" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Payment UPI Apps</p>
                      </Link>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link to="/paymentsetting" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Payment Settings</p>
                      </Link>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link to="/panelacces" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Panel Access</p>
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link to="/disputecmnt" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Dispute Comment</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <a href="/emailconfig" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Email Config</p>
                      </a>
                    </li> */}
                  </ul>
                </li>

                {/* <li
                  className={`nav-item has-treeview ${utilitiesDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleUtilitiesDropdown}
                  >
                    <FaBitbucket className="fab fa-bitbucket nav-icon" />
                    <p style={{ fontWeight: '600' }}>
                      Utilities
                      {utilitiesDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${utilitiesDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item">
                      <a href="/bankacc" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Bank Accounts</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/sendsms" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Send SMS</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/sendemail" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Send Email</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/sendnotification" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Send Notifications</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/newspanel" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>News Panel</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/loginalert" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>LogIn Alert</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/sliderimage" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Slider Image</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/enquiries" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Enquiries</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="refercondition" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Refer Condition</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/cashback" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Cashback Offers</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/promocode" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Promocodes</p>
                      </a>
                    </li>
                  </ul>
                </li> */}

                <li
                  className={`nav-item has-treeview ${permissionDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a href="#!" className="nav-link " onClick={togglePermissionDropdown}>
                    <BiSolidBriefcase className="fas fa-briefcase nav-icon" />
                    <p style={{ fontWeight: '600' }}>
                      Permission Management
                      {permissionDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>

                  <ul
                    className={`nav nav-treeview ${permissionDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item">
                      <Link to="/movetobankpermissiontable" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Bank Permission Table</p>
                      </Link>
                    </li>

                  </ul>
                </li>


                <li
                  className={`nav-item has-treeview ${nsdlDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a href="#!" className="nav-link " onClick={toggleNsdlDropdown}>
                    <BiSolidBriefcase className="fas fa-briefcase nav-icon" />
                    <p style={{ fontWeight: '600' }}>
                      NSDL Management
                      {nsdlDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>

                  <ul
                    className={`nav nav-treeview ${nsdlDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item">
                      <Link to="/nsdlkycfetch" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>NSDL Kyc Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adminkyc" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>NSDL KYC</p>
                      </Link>
                    </li>

                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${reportsDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleReportsDropdown}
                  >
                    <AiOutlineFileText className="nav-icon far fa-file-alt" />
                    <p style={{ fontWeight: '600' }}>
                      Reports
                      {reportsDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${reportsDropdownOpen ? "open" : ""
                      }`}
                    style={{ overflowX: "hidden", overflowY: "scroll" }}
                  >

                    <li className="nav-item">
                      <Link to="/viewallreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>View All Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/suspisiousfroudreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Suspicious Fraud Report</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <a href="/operatorwisereport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Operatorwise Report</p>
                      </a>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/rechargereport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Recharge Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adminAepsReport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>AEPS Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adminaadharpayreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>AadharPay Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adminpayoutreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Payout Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/allmovetobankuser" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>MoveToBank User Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adminbbpsreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>BBPS Report</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <a href="/salesreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Sales Report</p>
                      </a>
                    </li> */}

                    {/* <li className="nav-item">
                      <Link to="/ledgerreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Ledger Report</p>
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/adminmatmreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>MATM Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/matmlogreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>MATM Pending Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/viewallactiveinactive" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>All Active/Inactive Reports</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/viewtickets" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>View Tickets</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/viewpendingtickets" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>View Pending Tickets</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={'/assignticket'} className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>View Assign Tickets</p>
                      </Link>
                    </li>

                    <Link to='/kycreport' className="nav-link">
                      <BsSquareFill className="nav-icon" />
                      <p className="nav-text">KYC Report</p>
                    </Link>

                    {/* <Link to='/upireport' className="nav-link">
                      <BsSquareFill className="nav-icon" />
                      <p className="nav-text">UPI Report</p>
                    </Link> */}

                    <li className="nav-item">
                      <Link to="/couponreqreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Coupon Req. Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/admininitinsurance" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Wallet Debit Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/insurancereport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Insurance Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/admindmtreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>DMT Report</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <a href="/workingreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Working Report</p>
                      </a>
                    </li> */}

                    {/* <li className="nav-item">
                      <a href="/opnclsreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Opening Closing Report</p>
                      </a>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/earningreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Earning Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/selfearningreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Self Earning Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <a href="/admin2facountreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>2Fa Count Report</p>
                      </a>
                    </li>

                    {/* <li className="nav-item">
                      <a href="/paymentreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Payment Report</p>
                      </a>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/adminprofitreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Admin Profit Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adminnsdlreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>NSDL PAN Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/servicewisetxn" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Service Wise Txn Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/goldsilvertxn" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Gold/Silver Txn. Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/fundreqreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Fund Request Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adminlicreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>LIC Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/aepsuseractivity" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>AEPS User Activity Report</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/bankingreport" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Banking Report</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <a href="/downline" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Downline Txn Report</p>
                      </a>
                    </li> */}
                    {/* <li className="nav-item">
                      <a href="/downlinewallet" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Downline Wallet Report</p>
                      </a>
                    </li> */}
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${hrDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a href="#!" className="nav-link " onClick={toggleHrDropdown}>
                    <BiSolidBriefcase className="fas fa-briefcase nav-icon" />
                    <p style={{ fontWeight: '600' }}>
                      HR Management
                      {hrDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>

                  <ul
                    className={`nav nav-treeview ${hrDropdownOpen ? "open" : ""
                      }`}
                  >

                    {/* <li className="nav-item">
                      <Link to="/assignemp" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Assign Employee</p>
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/assignempreport" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>View Assigned Employee</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link to="/viewinactivetaskreport" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>View Inactive Task Report</p>
                      </Link>
                    </li> */}

                    <li className="nav-item">
                      <Link to="/employee" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Employee</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/empbyuser" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Employee By User</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/viewempdownlineusers" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>View Users</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/employeeusermap" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Employee User Map</p>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link to="/systemuser" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>System Users</p>
                      </Link>
                    </li> */}
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${webDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a href="#!" className="nav-link " onClick={toggleWebDropdown}>
                    <FaGlobe className="nav-icon fa fa-globe " />
                    <p style={{ fontWeight: '600' }}>
                      Software Management
                      {webDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${webDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item">
                      <a href="admin2falogin" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>2FA Login</p>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="location" className="nav-link ">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>Location</p>
                      </a>
                    </li>

                  </ul>
                </li>

                {/* <li
                  className={`nav-item has-treeview ${developerDropdownOpen ? "menu-open" : ""
                    }`}
                >
                  <a
                    href="#!"
                    className="nav-link "
                    onClick={toggleDeveloperDropdown}
                  >
                    <FaTools className="fas fa-tools nav-icon" />
                    <p style={{ fontWeight: '600' }}>
                      Developer Tools
                      {developerDropdownOpen ? (
                        <FaAngleDown className="fas fa-angle-down right" />
                      ) : (
                        <FaAngleLeft className="fas fa-angle-left right" />
                      )}
                    </p>
                  </a>
                  <ul
                    className={`nav nav-treeview ${developerDropdownOpen ? "open" : ""
                      }`}
                  >
                    <li className="nav-item">
                      <a href="#!" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>API Users</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/apikeysetting" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>API Key & Setting</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#!" className="nav-link">
                        <BsSquareFill className="fas fa-square nav-icon" />
                        <p>API Integration Tool</p>
                      </a>
                    </li>
                  </ul>
                </li> */}

              </ul>
            </nav>
          </div>
        </aside>
      </div>
    </div>
  );
}
