import './App.css';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import { UAParser } from 'ua-parser-js';
import Cookies from 'js-cookie';
import Loader from './components/Loader';


// Lazy load components
const Admin = lazy(() => import('./components/Admin'));
const Member = lazy(() => import('./components/Member'));
const Editmember = lazy(() => import('./components/Editmember'));
const Viewmember = lazy(() => import('./components/Viewmember'));
const Deactivemember = lazy(() => import('./components/Deactivemember'));
const Memberkyc = lazy(() => import('./components/Memberkyc'));
const Logindetail = lazy(() => import('./components/Logindetail'));
// const Membercommison = lazy(() => import('./components/Membercommison'));
const Transactionhis = lazy(() => import('./components/Transactionhis'));
const Pendingtrans = lazy(() => import('./components/Pendingtrans'));
const Memberlogindetail = lazy(() => import('./components/Memberlogindetail'));
const Refundtrans = lazy(() => import('./components/Refundtrans'));
const Walletsummary = lazy(() => import('./components/Walletsummary'));
const Fundtrans = lazy(() => import('./components/Fundtrans'));
const Transfered = lazy(() => import('./components/Transfered'));
const Fundreqmanager = lazy(() => import('./components/Fundreqmanager'));
const Virtualbal = lazy(() => import('./components/Virtualbal'));
const Profileupdate = lazy(() => import('./components/Profileupdate'));
const Changepaswrd = lazy(() => import('./components/Changepaswrd'));
const Dispute = lazy(() => import('./components/Dispute'));
const Contactinfo = lazy(() => import('./components/Contactinfo'));
const Custcare = lazy(() => import('./components/Custcare'));
const Packagemast = lazy(() => import('./components/Packagemast'));
const Editpackagemast = lazy(() => import('./components/Editpackagemast'));
const Servicemast = lazy(() => import('./components/Servicemast'));
const Operatormast = lazy(() => import('./components/Operatormast'));
const Rechargeset = lazy(() => import('./components/Rechargeset'));
const Upiapps = lazy(() => import('./components/Upiapps'));
const Paymentsetting = lazy(() => import('./components/Paymentsetting'));
const Disputecmnt = lazy(() => import('./components/Disputecmnt'));
const Emailconfig = lazy(() => import('./components/Emailconfig'));
const Sendsms = lazy(() => import('./components/Sendsms'));
const Sendemail = lazy(() => import('./components/Sendemail'));
const Sendnotification = lazy(() => import('./components/Sendnotification'));
const Newspanel = lazy(() => import('./components/Newspanel'));
const Loginalert = lazy(() => import('./components/Loginalert'));
const Sliderimage = lazy(() => import('./components/Sliderimage'));
const Promocode = lazy(() => import('./components/Promocode'));
const Createpromocd = lazy(() => import('./components/Createpromocd'));
const Viewpromocode = lazy(() => import('./components/Viewpromocd'));
const Employee = lazy(() => import('./components/Employee'));
const Empregist = lazy(() => import('./components/Empregist'));
const Editemp = lazy(() => import('./components/Editemp'));
const Viewemp = lazy(() => import('./components/Viewemp'));
const Addsystemuser = lazy(() => import('./components/Addsystemuser'));
const Updateuser = lazy(() => import('./components/Updateuser'));
const Updateautority = lazy(() => import('./components/Updateautority'));
const Operatorwisereport = lazy(() => import('./components/Operatorwisereport'));
// const Workingreport = lazy(() => import('./components/Workingreport'));
const Opnclsreport = lazy(() => import('./components/Opnclsreport'));
const Earningreport = lazy(() => import('./components/Earningreport'));
const Creddebtreport = lazy(() => import('./components/Creddebtreport'));
const Paymentreport = lazy(() => import('./components/Paymentreport'));
const Adminprofitreport = lazy(() => import('./components/Adminprofitreport'));
const Refundreport = lazy(() => import('./components/Refundreport'));
const Fundreqreport = lazy(() => import('./components/Fundreqreport'));
const Apicommison = lazy(() => import('./components/Apicommison'));
const Viewapi = lazy(() => import('./components/Viewapi'));
const Editapicommison = lazy(() => import('./components/Editapicommison'));
const Editapicredential = lazy(() => import('./components/Editapicredential'));
const Smsapi = lazy(() => import('./components/Smsapi'));
const Utilityapi = lazy(() => import('./components/Utilityapi'));
const Editutilityapi = lazy(() => import('./components/Editutilityapi'));
const Addmember = lazy(() => import('./components/Addmember'));
const Editcontact = lazy(() => import('./components/Editcontact'));
const Editnews = lazy(() => import('./components/Editnews'));
const Editdeactivemem = lazy(() => import('./components/Editdeactivemem'));
const Viewdeactivemem = lazy(() => import('./components/Viewdeactivemem'));
const Softmintlog = lazy(() => import('./components/Softmintlog'));
const RDService = lazy(() => import('./components/RDService'));
const Updatetransreport = lazy(() => import('./components/Updatetransreport'));
const Ledgerreport = lazy(() => import('./components/Ledgerreport'));
const Addkyc = lazy(() => import('./components/Addkyc'));
const Newloginpage = lazy(() => import('./components/Newloginpage'));
const Rechargereport = lazy(() => import('./components/Rechargereport'));
const Commission = lazy(() => import('./components/Commission'));
const Usercommission = lazy(() => import('./components/Usercommission'));
const Cards = lazy(() => import('./components/Cards'));
const Admincommission = lazy(() => import('./components/Admincommission'));
const AdminAepsReport = lazy(() => import('./components/AdminAepsReport'));
const ManageMiniWallet = lazy(() => import('./components/ManageMiniWallet'));
const Addslab = lazy(() => import('./components/Addslab'));
const ProviderName = lazy(() => import('./components/utilityComponents/ProviderName'));
const Kycreport = lazy(() => import('./components/Kycreport'));
// const AddAadharPayAgent = lazy(() => import('./components/AddAadharPayAgent'));
// const AadharPayLogReport = lazy(() => import('./components/AadharPayLogReport'));
// const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ViewTickets = lazy(() => import('./components/ViewTickets'));
const ViewTicketDetail = lazy(() => import('./components/ViewTicketDetail'));
const TicketChart = lazy(() => import('./components/TicketChart'));
const Editpermission = lazy(() => import('./components/Editpermission'));
const AdminPayoutReport = lazy(() => import('./components/AdminPayoutReport'));
const ChargeSetup = lazy(() => import('./components/ChargeSetup'));
const AddCharge = lazy(() => import('./components/AddCharge'));
const LedgerTxnReport = lazy(() => import('./components/LedgerTxnReport'));
const ViewPendingTickets = lazy(() => import('./components/ViewPendingTickets'));
const DetailedPendingViewTickets = lazy(() => import('./components/DetailedPendingViewTickets'));
const AssignTicket = lazy(() => import('./components/AssignTicket'));
const DetailAssignTicket = lazy(() => import('./components/DetailAssignTicket'));
const MovetobankPermissionTable = lazy(() => import('./components/MovetobankPermissionTable'));
const AddBankPermission = lazy(() => import('./components/AddBankPermission'));
const AllMovetobankUserReport = lazy(() => import('./components/AllMovetobankUserReport'));
const Updatemovetobankuser = lazy(() => import('./components/Updatemovetobankuser'));
const NsdlForm = lazy(() => import('./components/utilityComponents/NsdlForm'));
const NsdlReceipt = lazy(() => import('./components/utilityComponents/NsdlReceipt'));
const ViewAllReports = lazy(() => import('./components/ViewAllReports'));
const MasterAdminComm = lazy(() => import('./components/MasterAdminComm'));
const BbpsAdminComm = lazy(() => import('./components/BbpsAdminComm'));
const BbpsUserComm = lazy(() => import('./components/BbpsUserComm'));
const ViewAllActiveInactive = lazy(() => import('./components/ViewAllActiveInactive'));
const AddSlabs = lazy(() => import('./components/AddSlabs'));
const Expance = lazy(() => import('./components/Expance'));
const AddCommCharge = lazy(() => import('./components/AddCommCharge'));
const AdminMatmReport = lazy(() => import('./components/AdminMatmReport'));
const AdminAadharPayReport = lazy(() => import('./components/AdminAadharPayReport'));
const AddMatmAgent = lazy(() => import('./components/AddMatmAgent'));
const MatmLogReport = lazy(() => import('./components/MatmLogReport'));
const Changesponser = lazy(() => import('./components/Changesponser'));
const AddUserMap = lazy(() => import('./components/AddUserMap'));
const KycAgentView = lazy(() => import('./components/utilityComponents/KycAgentView'));
const SelfEarningReport = lazy(() => import('./components/SelfEarningReport'));
const DistributorMapping = lazy(() => import('./components/DistributorMapping'));
const ViewAepsUser = lazy(() => import('./components/ViewAepsUser'));
const AdminInitInsurance = lazy(() => import('./components/AdminInitInsurance'));
const EmployeeUserMap = lazy(() => import('./components/EmployeeUserMap'));
const AdminInsurance = lazy(() => import('./components/AdminInsurance'));
const AddUtiAgent = lazy(() => import('./components/AddUtiAgent'));
const NewUserPage = lazy(() => import('./components/NewUserPage'));
const CouponReqReport = lazy(() => import('./components/CouponReqReport'));
const SuspisiousFroudReport = lazy(() => import('./components/SuspisiousFroudReport'));
const FetchUserTxnActivity = lazy(() => import('./components/FetchUserTxnActivity'));
const ServiceWiseTxnReport = lazy(() => import('./components/ServiceWiseTxnReport'));
const AdminNsdlReport = lazy(() => import('./components/AdminNsdlReport'));
const InsuranceReport = lazy(() => import('./components/InsuranceReport'));
const AdminDmtReport = lazy(() => import('./components/AdminDmtReport'));
const AssignPackage = lazy(() => import('./components/AssignPackage'));
const AdminBbpsReport = lazy(() => import('./components/AdminBbpsReport'));
const Admin2FaCountReport = lazy(() => import('./components/Admin2FaCountReport'));
// const UpiReport = lazy(() => import('./components/UpiReport'));
// const AepsBusinesReport = lazy(() => import('./Employee Component/AepsBusinesReport'));
// const EmpMatmReport = lazy(() => import('./Employee Component/EmpMatmReport'));
// const QrBusinesReport = lazy(() => import('./Employee Component/QrBusinessReport'));
// const TopPerformerReport = lazy(() => import('./Employee Component/ToperformerReport'));
// const EmpUserWorkingReport = lazy(() => import('./Employee Component/EmpUserWorkingReport'));
// const CreateComplaint = lazy(() => import('./Employee Component/CreateComplaint'));
const GoldSilverTxnReport = lazy(() => import('./components/GoldSilverTxnReport'));
const ActuatorDetails = lazy(() => import('./components/ActuatorDetails'));
const AdminLicReport = lazy(() => import('./components/AdminLicReport'));
const AepsUserActivity = lazy(() => import('./components/AepsUserActivity'));
const AssignEmp = lazy(() => import('./components/AssignEmp'));
const AssignEmpReport = lazy(() => import('./components/AssignEmpReport'));
const ViewInactiveTaskReport = lazy(() => import('./components/ViewInactiveTaskReport'));
const Admin2FaLogin = lazy(() => import('./components/Admin2FaLogin'));
const Location = lazy(() => import('./components/Location'));
const AltSwitch = lazy(() => import('./components/AltSwitch'));
const NsdlKycfetch = lazy(() => import('./components/NsdlKycfetch'));
const EmpByUser = lazy(() => import('./components/EmpByUser'));
const ViewEmpDownlineUsers = lazy(() => import('./components/ViewEmpDownlineUsers'));
const ViewAdminEmpInactiveReport = lazy(() => import('./components/ViewAdminEmpInactiveReport'));
const AssignedEmpUserActivity = lazy(() => import('./components/AssignedEmpUserActivity'));
const AdminKyc = lazy(() => import('./components/AdminKyc'));
const ViewSummary = lazy(() => import('./components/ViewSummary'));
const BankingReport = lazy(() => import('./components/BankingReport'));


const Side = lazy(() => import('./retailer/Side'));
const Accountinfo = lazy(() => import('./retailer/Accountinfo'));
const Addmoney = lazy(() => import('./retailer/Addmoney'));
const Aepsreport = lazy(() => import('./retailer/Aepsreport'));
const Aepsservice = lazy(() => import('./retailer/Aepsservice'));
const Bankaccount = lazy(() => import('./retailer/Bankaccount'));
const Cashdeposit = lazy(() => import('./retailer/Cashdeposit'));
const Changepasswrd = lazy(() => import('./retailer/Changepasswrd'));
const Cms = lazy(() => import('./retailer/Cms'));
const Commison = lazy(() => import('./retailer/Commison'));
const Dmt = lazy(() => import('./retailer/Dmt'));
const Dth = lazy(() => import('./retailer/Dth'));
const Editprofile = lazy(() => import('./retailer/Editprofile'));
const Electricity = lazy(() => import('./retailer/Electricity'));
const Fastag = lazy(() => import('./retailer/Fastag'));
const Feedback = lazy(() => import('./retailer/Feedback'));
const Fundrequest = lazy(() => import('./retailer/Fundrequest'));
const Gasbooking = lazy(() => import('./retailer/Gasbooking'));
const Insurance = lazy(() => import('./retailer/Insurance'));
const Kyc = lazy(() => import('./retailer/Kyc'));
const Landline = lazy(() => import('./retailer/Landline'));
const Lic = lazy(() => import('./retailer/Lic'));
const Lpg = lazy(() => import('./retailer/Lpg'));
const Mobilepostpaid = lazy(() => import('./retailer/Mobilepostpaid'));
const Mobilerecharge = lazy(() => import('./retailer/Mobilerecharge'));
const Moneytrans = lazy(() => import('./retailer/Moneytrans'));
const Mpin = lazy(() => import('./retailer/Mpin'));
const Municipility = lazy(() => import('./retailer/Muncipility'));
const Nsdlpan = lazy(() => import('./retailer/Nsdlpan'));
const Pancard = lazy(() => import('./retailer/Pancard'));
const Payout = lazy(() => import('./retailer/Payout'));
const Rechargereportretailer = lazy(() => import('./retailer/Rechargereportretailer'));
const Sendmoney = lazy(() => import('./retailer/Sendmoney'));
const Wallettrans = lazy(() => import('./retailer/Wallettrans'));
const Water = lazy(() => import('./retailer/Water'));
const RetailerMoveToBankUserReport = lazy(() => import('./retailer/RetailerMoveToBankUserReport'));
const Editmovetobankuser = lazy(() => import('./retailer/EditMovetobankuser'));
const Sidebar = lazy(() => import('./retailer/Sidebar'));
const RetailerService = lazy(() => import('./retailer/RetailerService'));
const Biometric = lazy(() => import('./retailer/Biometric'));
const RetailerTxnReport = lazy(() => import('./retailer/RetailerTxnReport'));
const RegisterAeps = lazy(() => import('./retailer/RegisterAeps'));
const AepsLogin = lazy(() => import('./retailer/AepsLogin'));
const TicketRaise = lazy(() => import('./retailer/TicketRaise'));
const RetailerEarningReport = lazy(() => import('./retailer/RetailerEarningReport'));
const Nsdlreport = lazy(() => import('./retailer/Nsdlreport'));
const Bbps = lazy(() => import('./retailer/Bbps'));
const BbpsReport = lazy(() => import('./retailer/BbpsReport'));
const AadharpayService = lazy(() => import('./retailer/AadharpayService'));
const Aadhar2FaLogin = lazy(() => import('./retailer/Aadhar2FaLogin'));
const MATMReport = lazy(() => import('./retailer/MATMReport'));
const AadharPayReport = lazy(() => import('./retailer/AadharPayReport'));
const RetailerMatmLogReport = lazy(() => import('./retailer/RetailerMatmlogReport'));
const Broadband = lazy(() => import('./retailer/Broadband'));
const Cable = lazy(() => import('./retailer/Cable'));
const DataCardPrepaid = lazy(() => import('./retailer/DataCardPrepaid'));
const TrafficChallan = lazy(() => import('./retailer/TrafficChallan'));
const EmiPayment = lazy(() => import('./retailer/EmiPayment'));
const FeePayment = lazy(() => import('./retailer/FeePayment'));
const LoanRepayment = lazy(() => import('./retailer/LoanRepayment'));
const BroadbandPostpaid = lazy(() => import('./retailer/BroadbandPostpaid'));
const CableTv = lazy(() => import('./retailer/CableTv'));
const Emi = lazy(() => import('./retailer/Emi'));
const InitInsuranceReport = lazy(() => import('./retailer/InitInsuranceReport'));
const RetailerCouponReport = lazy(() => import('./retailer/RetailerCouponReport'));
const UtiPan = lazy(() => import('./retailer/UtiPan'));
const UtiAgentReport = lazy(() => import('./retailer/UtiAgentReport'));
const RetailerInsuranceReport = lazy(() => import('./retailer/RetailerInsuranceReport'));
const DmtRegister = lazy(() => import('./retailer/DmtRegister'));
const AddBeneficiary = lazy(() => import('./retailer/AddBeneficiary'));
const DmtReport = lazy(() => import('./retailer/DmtReport'));
const Kycmicroatm = lazy(() => import('./retailer/Kycmicroatm'));
const Retailer2FaCountReport = lazy(() => import('./retailer/Retailer2FaCountReport'));
const RetailerGoldSilverReport = lazy(() => import('./retailer/RetailerGoldSilverReport'));
const LicReport = lazy(() => import('./retailer/LicReport'));
const RetailerKyc = lazy(() => import('./retailer/RetailerKyc'));
const Loan = lazy(() => import('./retailer/Loan'));
const AccountOpen = lazy(() => import('./retailer/AccountOpen'));
const RetailerBankingReport = lazy(() => import('./retailer/RetailerBankingReport'));
// const CashDeposit2Fa = lazy(() => import('./retailer/CashDeposit2Fa'));
// const CashDepositPage = lazy(() => import('./retailer/CashDepositPage'));


const EmployeeDashboard = lazy(() => import('./Employee Component/EmployeeDashboard'));
const EmployeeNavbar = lazy(() => import('./Employee Component/EmployeeNavbar'));
const FetchEmp = lazy(() => import('./Employee Component/FetchEmp'));
const AddEmployee = lazy(() => import('./Employee Component/AddEmployee'));
const EditEmployee = lazy(() => import('./Employee Component/EditEmployee'));
const EmpDownlineUsers = lazy(() => import('./Employee Component/EmpDownlineUsers'));
const DownlineByEmpId = lazy(() => import('./Employee Component/DownlineByEmpId'));
const ViewEmpInactiveTask = lazy(() => import('./Employee Component/ViewEmpInactiveTask'));
const ViewAssignedEmp = lazy(() => import('./Employee Component/ViewAssignedEmp'));
const ServicewiseEmpReport = lazy(() => import('./Employee Component/ServicewiseEmpReport'));

const LazyToastContainer = lazy(() => import('./LazyToastContainer'));

function App() {

  // const [isLoading, setLoading] = useState(true);

  if (Cookies.get('deviceId') === undefined) {
    var fingerprint = "";
    const uaparser = new UAParser();
    fingerprint += navigator.userAgent;
    fingerprint += navigator.language;
    fingerprint += window.screen.colorDepth;
    fingerprint += window.screen.height;
    fingerprint += window.screen.width;
    fingerprint += uaparser.getResult().os.name;
    Cookies.set('deviceId', fingerprint, { expires: 1 });
  }


  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  const [deviceID, setDeviceID] = useState('');

  useEffect(() => {
    // Check if device ID and timestamp already exist in local storage
    let storedDeviceID = localStorage.getItem('deviceID');
    let storedTimestamp = localStorage.getItem('deviceIDTimestamp');

    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 1 day in milliseconds

    if (!storedDeviceID || !storedTimestamp || Date.now() - storedTimestamp > oneDayInMilliseconds) {
      // Generate a new device ID if it doesn't exist or if it's older than one day
      storedDeviceID = generateDeviceID();
      storedTimestamp = Date.now();
      localStorage.setItem('deviceID', storedDeviceID);
      localStorage.setItem('deviceIDTimestamp', storedTimestamp);
    }

    setDeviceID(storedDeviceID);
  }, []);

  const generateDeviceID = () => {
    // Generate a unique device ID
    return 'device-' + Math.random().toString(36).substring(2, 9);
  };


  return (
    <BrowserRouter>
      {/* {isLoading && <Loader />} */}
      <Suspense fallback={<div><Loader /></div>}>
        <LazyToastContainer />

        <Routes>
          <Route path='/' element={<Newloginpage />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/member' element={<Member />} />
          <Route path='/newuserpage' element={<NewUserPage />} />
          <Route path='/addmember' element={<Addmember />} />
          <Route path='/editmember' element={<Editmember />} />
          <Route path='/navbar' element={<Navbar />} />
          <Route path='/viewmember' element={<Viewmember />} />
          <Route path='/deactivemember' element={<Deactivemember />} />
          <Route path='/editdeactivemem' element={<Editdeactivemem />} />
          <Route path='/viewdeactivemem' element={<Viewdeactivemem />} />
          <Route path='/memberkyc' element={<Memberkyc />} />
          <Route path='/logindetail' element={<Logindetail />} />
          {/* <Route path='/membercommison' element={<Membercommison />} /> */}
          <Route path='/transactionhis' element={<Transactionhis />} />
          <Route path='/pendingtrans' element={<Pendingtrans />} />
          <Route path='/memberlogindetail' element={<Memberlogindetail />} />
          <Route path='/refundtrans' element={<Refundtrans />} />
          <Route path='/walletsummary' element={<Walletsummary />} />
          <Route path='/fundtrans' element={<Fundtrans />} />
          <Route path='/transfered' element={<Transfered />} />
          <Route path='/fundreqmanager' element={<Fundreqmanager />} />
          <Route path='/virtualbal' element={<Virtualbal />} />
          <Route path='/profileupdate' element={<Profileupdate />} />
          <Route path='/changepaswrd' element={<Changepaswrd />} />
          <Route path='/dispute' element={<Dispute />} />
          <Route path='/contactinfo' element={<Contactinfo />} />
          <Route path='/custcare' element={<Custcare />} />
          <Route path='/packagemast' element={<Packagemast />} />
          <Route path='/editpackagemast' element={<Editpackagemast />} />
          <Route path='/servicemast' element={<Servicemast />} />
          <Route path='/operatormast' element={<Operatormast />} />
          <Route path='/rechargeset' element={<Rechargeset />} />
          <Route path='/upiapps' element={<Upiapps />} />
          <Route path='/paymentsetting' element={<Paymentsetting />} />
          <Route path='/disputecmnt' element={<Disputecmnt />} />
          <Route path='/emailconfig' element={<Emailconfig />} />
          <Route path='/sendsms' element={<Sendsms />} />
          <Route path='/changesponser' element={<Changesponser />} />
          <Route path='/sendemail' element={<Sendemail />} />
          <Route path='/sendnotification' element={<Sendnotification />} />
          <Route path='/newspanel' element={<Newspanel />} />
          <Route path='/editnews' element={<Editnews />} />
          <Route path='/loginalert' element={<Loginalert />} />
          <Route path='/sliderimage' element={<Sliderimage />} />
          <Route path='/promocode' element={<Promocode />} />
          <Route path='/createpromocd' element={<Createpromocd />} />
          <Route path='/viewpromocd' element={<Viewpromocode />} />
          <Route path='/employee' element={<Employee />} />
          <Route path='/empregist' element={<Empregist />} />
          <Route path='/editemp' element={<Editemp />} />
          <Route path='/viewemp' element={<Viewemp />} />
          <Route path='/addsystemuser' element={<Addsystemuser />} />
          <Route path='/updateuser' element={<Updateuser />} />
          <Route path='/updateautority' element={<Updateautority />} />
          <Route path='/operatorwisereport' element={<Operatorwisereport />} />
          {/* <Route path='/workingreport' element={<Workingreport />} /> */}
          <Route path='/opnclsreport' element={<Opnclsreport />} />
          <Route path='/earningreport' element={<Earningreport />} />
          <Route path='/creddebtreport' element={<Creddebtreport />} />
          <Route path='/paymentreport' element={<Paymentreport />} />
          <Route path='/adminprofitreport' element={<Adminprofitreport />} />
          <Route path='/refundreport' element={<Refundreport />} />
          <Route path='/fundreqreport' element={<Fundreqreport />} />
          <Route path='/apicommison' element={<Apicommison />} />
          <Route path='/viewapi' element={<Viewapi />} />
          <Route path='/editapicommison' element={<Editapicommison />} />
          <Route path='/editapicredential' element={<Editapicredential />} />
          <Route path='/smsapi' element={<Smsapi />} />
          <Route path='/utilityapi' element={<Utilityapi />} />
          <Route path='/editutilityapi' element={<Editutilityapi />} />
          <Route path='/editcontact' element={<Editcontact />} />
          <Route path='/softmintlog' element={<Softmintlog />} />
          <Route path='/rdservice' element={<RDService />} />
          <Route path='/updatetransreport' element={<Updatetransreport />} />
          <Route path='/ledgerreport' element={<Ledgerreport />} />
          <Route path='/addkyc' element={<Addkyc />} />
          <Route path='/rechargereport' element={<Rechargereport />} />
          {/* <Route path='/loader' element={<Loader />} /> */}
          <Route path='/commission' element={<Commission />} />
          <Route path='/usercommission' element={<Usercommission />} />
          <Route path='/addslab' element={<Addslab />} />
          <Route path='/providername' element={<ProviderName />} />
          <Route path='/kycreport' element={<Kycreport />} />
          <Route path='/viewtickets' element={<ViewTickets />} />
          <Route path='/viewticketdetail' element={<ViewTicketDetail />} />
          <Route path='/ticketchart' element={<TicketChart />} />
          <Route path='/editpermission' element={<Editpermission />} />
          <Route path='/adminpayoutreport' element={<AdminPayoutReport />} />
          <Route path='/chargesetup' element={<ChargeSetup />} />
          <Route path='/addcharge' element={<AddCharge />} />
          <Route path='/ledgertxnreport' element={<LedgerTxnReport />} />
          <Route path='/viewpendingtickets' element={<ViewPendingTickets />} />
          <Route path='/detailedpendingviewticket' element={<DetailedPendingViewTickets />} />
          <Route path='/assignticket' element={<AssignTicket />} />
          <Route path='/detailassignticket' element={<DetailAssignTicket />} />
          <Route path='/movetobankpermissiontable' element={<MovetobankPermissionTable />} />
          <Route path='/addbankpermission' element={<AddBankPermission />} />
          <Route path='/allmovetobankuser' element={<AllMovetobankUserReport />} />
          <Route path='/updatemovetobankuser' element={<Updatemovetobankuser />} />
          <Route path='/nsdlform' element={<NsdlForm />} />
          <Route path='/nsdlreceipt' element={<NsdlReceipt />} />
          <Route path='/viewallreport' element={<ViewAllReports />} />
          <Route path='/masteradmincomm' element={<MasterAdminComm />} />
          <Route path='/bbpsadmincomm' element={<BbpsAdminComm />} />
          <Route path='/bbpsusercomm' element={<BbpsUserComm />} />
          <Route path='/viewallactiveinactive' element={<ViewAllActiveInactive />} />
          <Route path='/addslabs' element={<AddSlabs />} />
          <Route path='/expanse' element={<Expance />} />
          <Route path='/addcommcharge' element={<AddCommCharge />} />
          <Route path='/adminmatmreport' element={<AdminMatmReport />} />
          <Route path='/adminaadharpayreport' element={<AdminAadharPayReport />} />
          <Route path='/addmatmagent' element={<AddMatmAgent />} />
          <Route path='/matmlogreport' element={<MatmLogReport />} />
          <Route path='/addusermap' element={<AddUserMap />} />
          <Route path='/kycagentview' element={<KycAgentView />} />
          <Route path='/selfearningreport' element={<SelfEarningReport />} />
          <Route path='/distributormapping' element={<DistributorMapping />} />
          <Route path='/admininitinsurance' element={<AdminInitInsurance />} />
          <Route path='/employeeusermap' element={<EmployeeUserMap />} />
          <Route path='/admininsurance' element={<AdminInsurance />} />
          <Route path='/suspisiousfroudreport' element={<SuspisiousFroudReport />} />
          <Route path='/addutiagent' element={<AddUtiAgent />} />
          {/* <Route path='/addglobalpancharge' element={<AddGlobalPanCharge />} /> */}
          <Route path='/couponreqreport' element={<CouponReqReport />} />
          <Route path='/fetchusertxn' element={<FetchUserTxnActivity />} />
          <Route path='/servicewisetxn' element={<ServiceWiseTxnReport />} />
          <Route path='/adminnsdlreport' element={<AdminNsdlReport />} />
          <Route path='/insurancereport' element={<InsuranceReport />} />
          <Route path='/admindmtreport' element={<AdminDmtReport />} />
          <Route path='/adminbbpsreport' element={<AdminBbpsReport />} />
          <Route path='/admin2facountreport' element={<Admin2FaCountReport />} />
          {/* <Route path='/upireport' element={<UpiReport />} /> */}
          <Route path='/goldsilvertxn' element={<GoldSilverTxnReport />} />
          <Route path='/actuator' element={<ActuatorDetails />} />
          <Route path='/adminlicreport' element={<AdminLicReport />} />
          <Route path='/aepsuseractivity' element={<AepsUserActivity />} />
          <Route path='/assignemp' element={<AssignEmp />} />
          <Route path='/assignempreport' element={<AssignEmpReport />} />
          <Route path='/viewinactivetaskreport' element={<ViewInactiveTaskReport />} />
          <Route path='/admin2falogin' element={<Admin2FaLogin />} />
          <Route path='/admin2falogin' element={<Admin2FaLogin />} />
          <Route path='/location' element={<Location />} />
          <Route path='/altswitch' element={<AltSwitch />} />
          <Route path='/nsdlkycfetch' element={<NsdlKycfetch />} />
          <Route path='/empbyuser' element={<EmpByUser />} />
          <Route path='/viewempdownlineusers' element={<ViewEmpDownlineUsers />} />
          <Route path='/viewadminempinactivereport' element={<ViewAdminEmpInactiveReport />} />
          <Route path='/assignedempuseractivity' element={<AssignedEmpUserActivity />} />
          <Route path='/adminkyc' element={<AdminKyc />} />
          <Route path='/viewsummary' element={<ViewSummary />} />
          <Route path='/bankingreport' element={<BankingReport />} />


          {/* For retailer */}
          <Route path='/side' element={<Side />} />
          <Route path='/sidebar' element={<Sidebar />} />
          <Route path='/accountinfo' element={<Accountinfo />} />
          <Route path='/addmoney' element={<Addmoney />} />
          <Route path='/aepsreport' element={<Aepsreport />} />
          <Route path='/aepsservice' element={<Aepsservice />} />
          <Route path='/bankaccount' element={<Bankaccount />} />
          <Route path='/cashdeposit' element={<Cashdeposit />} />
          <Route path='/changepasswrd' element={<Changepasswrd />} />
          <Route path='/cms' element={<Cms />} />
          <Route path='/commison' element={<Commison />} />
          <Route path='/dmt' element={<Dmt />} />
          <Route path='/dth' element={<Dth />} />
          <Route path='/editprofile' element={<Editprofile />} />
          <Route path='/electricity' element={<Electricity />} />
          <Route path='/fastag' element={<Fastag />} />
          <Route path='/feedback' element={<Feedback />} />
          <Route path='/fundrequest' element={<Fundrequest />} />
          <Route path='/gasbooking' element={<Gasbooking />} />
          <Route path='/insurance' element={<Insurance />} />
          <Route path='/kyc' element={<Kyc />} />
          <Route path='/landline' element={<Landline />} />
          <Route path='/lic' element={<Lic />} />
          <Route path='/licreport' element={<LicReport />} />
          {/* <Route path='/loader' element={<Loader />} /> */}
          <Route path='/lpg' element={<Lpg />} />
          <Route path='/mobilepostpaid' element={<Mobilepostpaid />} />
          <Route path='/mobilerecharge' element={<Mobilerecharge />} />
          <Route path='/moneytrans' element={<Moneytrans />} />
          <Route path='/mpin' element={<Mpin />} />
          <Route path='/muncipility' element={<Municipility />} />
          <Route path='/nsdlpan' element={<Nsdlpan />} />
          <Route path='/pancard' element={<Pancard />} />
          <Route path='/payout' element={<Payout />} />
          <Route path='/rechargereportretailer' element={<Rechargereportretailer />} />
          <Route path='/sendmoney' element={<Sendmoney />} />
          <Route path='/retailerservice' element={<RetailerService />} />
          <Route path='/wallettrans' element={<Wallettrans />} />
          <Route path='/water' element={<Water />} />
          <Route path='/cards' element={<Cards />} />
          <Route path='/admincommission' element={<Admincommission />} />
          <Route path='/adminAepsReport' element={<AdminAepsReport />} />
          <Route path='/manageMiniWallet' element={<ManageMiniWallet />} />
          <Route path='/biometric' element={<Biometric />} />
          <Route path='/retailertxnreport' element={<RetailerTxnReport />} />
          <Route path='/registeraeps' element={<RegisterAeps />} />
          <Route path='/aepslogin' element={<AepsLogin />} />
          <Route path='/ticketraise' element={<TicketRaise />} />
          <Route path='/retailerearningreport' element={<RetailerEarningReport />} />
          <Route path='/retailermovetobankuserreport' element={<RetailerMoveToBankUserReport />} />
          <Route path='/editmovetobankuser' element={<Editmovetobankuser />} />
          <Route path='/nsdlreport' element={<Nsdlreport />} />
          <Route path='/bbps' element={<Bbps />} />
          <Route path='/bbpsreport' element={<BbpsReport />} />
          <Route path='/aadharpayservice' element={<AadharpayService />} />
          <Route path='/aadhar2falogin' element={<Aadhar2FaLogin />} />
          <Route path='/matmreport' element={<MATMReport />} />
          <Route path='/aadharpayreport' element={<AadharPayReport />} />
          <Route path='/retailermatmlogreport' element={<RetailerMatmLogReport />} />
          <Route path='/broadband' element={<Broadband />} />
          <Route path='/cable' element={<Cable />} />
          <Route path='/datacardprepaid' element={<DataCardPrepaid />} />
          <Route path='/trafficchallan' element={<TrafficChallan />} />
          <Route path='/emipayment' element={<EmiPayment />} />
          <Route path='/feepayment' element={<FeePayment />} />
          <Route path='/loanrepayment' element={<LoanRepayment />} />
          <Route path='/broadbandpostpaid' element={<BroadbandPostpaid />} />
          <Route path='/cabletv' element={<CableTv />} />
          <Route path='/emi' element={<Emi />} />
          <Route path='/viewaepsuser' element={<ViewAepsUser />} />
          <Route path='/initinsurancereport' element={<InitInsuranceReport />} />
          <Route path='/utipan' element={<UtiPan />} />
          <Route path='/utiagentreport' element={<UtiAgentReport />} />
          <Route path='/retailercouponreport' element={<RetailerCouponReport />} />
          <Route path='/retailerinsurancereport' element={<RetailerInsuranceReport />} />
          <Route path='/dmtregister' element={<DmtRegister />} />
          <Route path='/addbeneficiary' element={<AddBeneficiary />} />
          <Route path='/dmtreport' element={<DmtReport />} />
          <Route path='/assignpackage' element={<AssignPackage />} />
          <Route path='/kycmicroatm' element={<Kycmicroatm />} />
          <Route path='/retailer2facountreport' element={<Retailer2FaCountReport />} />
          <Route path='/retailergoldsilverreport' element={<RetailerGoldSilverReport />} />
          <Route path='/retailerkyc' element={<RetailerKyc />} />
          <Route path='/loan' element={<Loan />} />
          <Route path='/accountopen' element={<AccountOpen />} />
          <Route path='/retailerbankingreport' element={<RetailerBankingReport />} />
          {/* <Route path='cashdepositpage' element={<CashDepositPage />} />
          <Route path='cashdeposit2fa' element={<CashDeposit2Fa />} /> */}

          {/* <Route path='printcertificate' element={<PrintCertificate />} /> */}

          {/* For Employee */}

          <Route path='/employeedashboard' element={<EmployeeDashboard />} />
          <Route path='/employeeNavbar' element={<EmployeeNavbar />} />
          <Route path='/fetchemp' element={<FetchEmp />} />
          <Route path='/addemployee' element={<AddEmployee />} />
          <Route path='/editemployee' element={<EditEmployee />} />
          <Route path='/empdownlineusers' element={<EmpDownlineUsers />} />
          <Route path='/downlinebyempid' element={<DownlineByEmpId />} />
          <Route path='/viewempinactivetask' element={<ViewEmpInactiveTask />} />
          <Route path='/viewassignedemp' element={<ViewAssignedEmp />} />
          <Route path='/servicewiseempreport' element={<ServicewiseEmpReport />} />

        </Routes>
      </Suspense>

    </BrowserRouter>
  );
}

export default App;
